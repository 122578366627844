










































import './styles/ConstructPacks.scss';
import './styles/ConstructPacksAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IWorkListState, IConstructPacks } from '@store/modules/work-list/interfaces/Interfaces';
import { WorkListMutations } from '@store/modules/work-list/Types';
import { IPackED } from "@store/modules/project-documents/Interfaces";

const NSWorkList = namespace('storeWorkList');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'ConstructPacks',

    components: {
        'BaseButton': () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class ConstructPacks extends Vue {
    @Prop({ default: false }) usingInExecutiveDocs!: boolean;
    @NSDocuments.Getter('packPDList') packPDList!: any[];
    @NSDocuments.Getter('agreementProcessesPackPDLevel') agreementProcessesPackPDLevel!: string[];
    @NSWorkList.State((state: IWorkListState) => state.viewingPackTitle) viewingPackTitle!: string;
    @NSWorkList.State((state: IWorkListState) => state.constructPacks) constructPacks!: IConstructPacks;
    @NSWorkList.State((state: IWorkListState) => state.viewingPackExecutive) viewingPackExecutive!: any;
    @NSWorkList.Mutation(WorkListMutations.M_SET_VIEWING_PACK) setViewingPack!: (packKey: string) => void;
    @NSWorkList.Mutation(WorkListMutations.M_SET_VIEWING_PACK_EXECUTIVE) setViewingPackExecutive!: (packKey: string) => void;
    @NSWorkList.Mutation(WorkListMutations.M_SET_VIEWING_PACK_TITLE)
    setViewingPackTitle!: (packKey: string) => void;

    get constructPacksKeys() {
        return Object.keys(this.constructPacks);
    }

    get items() {
        if (this.usingInExecutiveDocs) {
            return this.packPDList.filter(item => item.packED.length);
        }
        return this.constructPacksKeys;
    }

    selectPackToView(packKey: string) {
        if (this.usingInExecutiveDocs) {
            this.setViewingPackExecutive(packKey);
        } else {
            this.setViewingPack(packKey);
        }
        this.setViewingPackTitle(packKey);
    }

    viewingPackEqualPackKey(packKey: any) {
        if (this.usingInExecutiveDocs) {
            return packKey.title === this.viewingPackExecutive;
        }
        return this.viewingPackTitle === packKey;
    }

    showPackNotification(packKey) {
        const packEDs: IPackED[] = packKey.packED;
        return this.agreementProcessesPackPDLevel.includes(packKey.uuid) && packEDs.filter(item => item.pddTask).length > 0;
    }
}
